<template>
  <article id="share-pixel-page">
    <h1>Share Pixels</h1>
    <v-card class="pa-4">
      <v-form ref="form">
        <v-row dense>
          <v-col cols="6">
            <label>ID tài khoản quảng cáo </label>
            <v-textarea v-model="listID" :rules="rules.listID" :placeholder="placeholder"></v-textarea>
          </v-col>
          <v-col cols="6">
            <label>Danh sách Pixel</label>
            <v-data-table
              v-model="pixelID"
              :headers="headers"
              :items="listPixelID"
              item-key="id"
              single-select
              show-select
              hide-default-footer
              hide-default-header
              class="elevation-0"
              height="300"
            >
              <template v-slot:item.description="{ item }">
                <span class="font-weight-bold">{{ item.description || '-' }}</span>
                <div>{{ item.id || '-' }}</div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-btn :loading="loading" @click="onSharePixel()" depressed color="primary" class="mr-2">Share Pixel</v-btn>
      </v-form>
    </v-card>
    <v-card v-if="shareSuccessful" class="pa-4 mt-4" elevation="0">
      <div class="d-flex justify-space-between align-center justify-center">
        <p class="font-weight-bold">KẾT QUẢ</p>
        <v-icon style="cursor: pointer; margin-top: -16px" @click="shareSuccessful = false">
          mdi-close
        </v-icon>
      </div>
      <div class="field">
        <span class="label">Ngày share: </span> <span class="detail">{{ detailResult.createdAt }}</span>
      </div>
      <div class="field">
        <span class="label">Pixel:</span> <span class="detail"> {{ detailResult.pixelId }}</span>
      </div>
      <div class="field">
        <span class="label">Success:</span>
        <div class="detail successful">
          <span v-for="item in detailResult.successIds" :key="item"> {{ item }} <br /> </span>
        </div>
      </div>
      <div class="field">
        <span class="label">Failed:</span>
        <div class="detail err">
          <span v-for="item in detailResult.failedIds" :key="item"> {{ item }} <br /> </span>
        </div>
      </div>
    </v-card>

    <v-snackbar v-model="snackbar" top centered color="error">
      Vui lòng chọn pixel!
    </v-snackbar>
  </article>
</template>

<script>
import pixelService from '@/apis/pixel';

export default {
  data() {
    return {
      listID: null,
      listPixelID: [],
      pixelID: [],
      rules: {
        listID: [v => (v && v.length > 0) || 'This field is required'],
        pixelID: [v => (v && v.length > 0) || 'This field is required'],
      },
      loading: false,
      showDialog: false,
      placeholder: '1 item on a single line\nID_Ads_1\nID_Ads_2\n...\nID_Ads_10',
      selected: [],
      headers: [
        {
          text: 'Description',
          value: 'description',
          sortable: false,
        },
      ],
      detailResult: {},
      shareSuccessful: false,
      snackbar: false,
    };
  },

  methods: {
    async getPixels() {
      try {
        const { data } = await pixelService.getPixels();
        if (data) {
          this.listPixelID = data;
        }
      } catch (error) {
        console.log('🚀 ~ file: index.vue:93 ~ getPixels ~ error:', error);
      }
    },
    async onSharePixel() {
      if (this.$refs.form.validate()) {
        if (this.pixelID.length === 0) {
          this.snackbar = true;
          return;
        }
        try {
          this.loading = true;
          const dataPost = {
            pixelId: this.pixelID[0].id,
            adsIds: this.listID
              .split('\n')
              .map(item => item.trim())
              .filter(item => !!item),
          };
          const re = await pixelService.sharePixel(dataPost);
          if (re.data.result) {
            this.detailResult = re.data.result;
            this.shareSuccessful = true;
          }
          this.$refs.form.resetValidation();
          this.listID = '';
          this.pixelID = [];
        } catch (error) {
          console.log('🚀 ~ file: index.vue:87 ~ onSharePixel ~ error:', error);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  created() {
    this.getPixels();
  },
  mounted() {
    const currentSub = JSON.parse(localStorage.getItem('current-subscription'));
    if (currentSub.data == null || currentSub?.data?.origin?.code !== 'LT_DROP_VIP') {
      this.$router.push('/admin');
    }
  },
};
</script>
<style lang="scss" scoped>
.field {
  margin-bottom: 4px;
  display: flex;

  .label {
    font-weight: bold;
    width: 100px;
    display: inline-block;
  }

  .detail {
    font-weight: normal;
  }

  .successful {
    color: #0bcf21;
  }

  .err {
    color: #c9302c;
  }
}
</style>
